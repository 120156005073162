import Vue from 'vue';
import VueRouter from 'vue-router';
import PublicLayout from '@/layouts/main';
import LoginLayout from '@/layouts/login';
import DashboardLayout from '@/layouts/dashboard';
import { getAuthUser, hasAuthUser, removeStorage } from "@/Util/auth";

Vue.use(VueRouter);

const routes = [
    {
        path: '/admin',
        redirect: '/admin/patient-profile',
        component: DashboardLayout,
        meta: {
            authAdminRequired: true,
        },
        children: [
            {
                path: '/admin/patient-profile',
                name: 'AdminPatientProfile',
                meta: {
                    breadcrumbs: 'Patient List',
                },
                component: () => import('@/views/admin/patientProfile'),
            },
            {
                path: '/admin/patient-profile/:operation/:id?',
                name: 'AdminPatientProfileForm',
                meta: {
                    breadcrumbs: 'Patient Profile Form',
                },
                component: () => import('@/views/admin/patientProfile/form'),
            },
            {
                path: '/admin/model-profile',
                name: 'AdminModelProfile',
                meta: {
                    breadcrumbs: 'Model Profile List',
                },
                component: () => import('@/views/admin/modelProfile'),
            },
            {
                path: '/admin/model-profile/:operation/:id?',
                name: 'AdminModelProfileForm',
                meta: {
                    breadcrumbs: 'Model Profile Form',
                },
                component: () => import('@/views/admin/modelProfile/form'),
            },
            {
                path: '/admin/users',
                name: 'AdminUser',
                meta: {
                    breadcrumbs: 'User List',
                },
                component: () => import('@/views/admin/users'),
            },
            {
                path: '/admin/user/patient/:id',
                name: 'AdminUserPatientForm',
                meta: {
                    breadcrumbs: 'User Patient Form',
                },
                component: () => import('@/views/admin/users/patient'),
            },
            {
                path: '/admin/user/:operation/:id?',
                name: 'AdminUserForm',
                meta: {
                    breadcrumbs: 'User Form',
                },
                component: () => import('@/views/admin/users/form'),
            },
            {
                path: '/admin/iadl-profile',
                name: 'AdminIadlProfile',
                meta: {
                    breadcrumbs: 'Iadl Profile List',
                },
                component: () => import('@/views/admin/iadlProfile'),
            },
            {
                path: '/admin/ideal-profile/:operation/:id?',
                name: 'AdminIadlProfileForm',
                meta: {
                    breadcrumbs: 'Iadl Profile Form',
                },
                component: () => import('@/views/admin/iadlProfile/form'),
            },
            {
                path: '/admin/frailty',
                name: 'AdminFrailty',
                meta: {
                    breadcrumbs: 'Frailty List',
                },
                component: () => import('@/views/admin/frailty'),
            },
            {
                path: '/admin/frailty/:operation/:id?',
                name: 'AdminFrailtyFrom',
                meta: {
                    breadcrumbs: 'Frailty Form',
                },
                component: () => import('@/views/admin/frailty/form'),
            },
            {
                path: '/admin/quality/of/life',
                name: 'AdminQualityOfLife',
                meta: {
                    breadcrumbs: 'Quality Of Life List',
                },
                component: () => import('@/views/admin/qualityOfLife'),
            },
            {
                path: '/admin/quality/of/life/:operation/:id?',
                name: 'AdminQualityOfLifeForm',
                meta: {
                    breadcrumbs: 'Quality Of Life Form',
                },
                component: () => import('@/views/admin/qualityOfLife/form'),
            },
            {
                path: '/admin/configuration',
                name: 'AdminConfiguration',
                meta: {
                    breadcrumbs: 'Configuration',
                },
                component: () => import('@/views/admin/configurations'),
            },
            {
                path: '/admin/configuration/wp4/:operation/:id',
                name: 'AdminConfigurationWp4Form',
                meta: {
                    breadcrumbs: 'Configuration Wp4 Form',
                },
                component: () => import('@/views/admin/configurations/wp4/form'),
            },
            {
                path: '/admin/configuration/section/:operation/:id',
                name: 'AdminConfigurationWp4SectionForm',
                meta: {
                    breadcrumbs: 'Configuration Wp4 Section Form',
                },
                component: () => import('@/views/admin/configurations/wp4/sections/form'),
            },
            {
                path: '/admin/configuration/wp5/:operation/:id',
                name: 'AdminConfigurationWp5Form',
                meta: {
                    breadcrumbs: 'Configuration Wp5 Form',
                },
                component: () => import('@/views/admin/configurations/wp5/form'),
            },
            {
                path: '/admin/configuration/wp6/:operation/:id',
                name: 'AdminConfigurationWp6Form',
                meta: {
                    breadcrumbs: 'Configuration Wp6 Form',
                },
                component: () => import('@/views/admin/configurations/wp6/form'),
            },
            {
                path: '/admin/translation',
                name: 'AdminTranslation',
                meta: {
                    breadcrumbs: 'Translation',
                },
                component: () => import('@/views/admin/translations'),
            },
            {
                path: '/admin/translation/wp6/:operation/:id',
                name: 'AdminTranslationWp6Form',
                meta: {
                    breadcrumbs: 'Translation Wp6 Form',
                },
                component: () => import('@/views/admin/translations/wp6/form'),
            },
            {
                path: '/admin/translation/section/:operation/:id',
                name: 'AdminTranslationSectionForm',
                meta: {
                    breadcrumbs: 'Translation Section Form',
                },
                component: () => import('@/views/admin/translations/sections/form'),
            },
            {
                path: '/admin/translation/wp4/:operation/:id',
                name: 'AdminTranslationWp4Form',
                meta: {
                    breadcrumbs: 'Translation Wp4 Form',
                },
                component: () => import('@/views/admin/translations/wp4/form'),
            },
            {
                path: '/admin/translation/wp5/:operation/:id',
                name: 'AdminTranslationWp5Form',
                meta: {
                    breadcrumbs: 'Translation Wp5 Form',
                },
                component: () => import('@/views/admin/translations/wp5/form'),
            },
        ]
    },
    {
        path: '/admin/login',
        component: LoginLayout,
        meta: {
            authAdminRequired: false,
        },
        children: [
            {
                path: '',
                name: 'AdminLogin',
                component: () => import('@/views/admin/login'),
            },
        ],
    },
    {
        path: '/',
        redirect: '/login',
        component: PublicLayout,
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/login',
        component: LoginLayout,
        meta: {
            authRequired: false,
        },
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import('@/views/login'),
            },
        ],
    },
    {
        path: '/forgot-password',
        component: LoginLayout,
        meta: {
            authRequired: false,
        },
        children: [
            {
                path: '',
                name: 'forgot password',
                component: () => import('@/views/forgot-pass'),
            },
        ],
    },
    {
        path: '/resetpass',
        component: LoginLayout,
        children: [
            {
                path: '',
                name: 'New Password',
                query: {
                    email: '',
                    token: '',
                },
                component: () => import('@/views/resetPassword'),
            },
        ],
    },
    {
        path: '/new/user/set/password',
        component: LoginLayout,
        children: [
            {
                path: '',
                name: 'NewUserPassword',
                query: {
                    email: '',
                    token: '',
                },
                component: () => import('@/views/resetPassword/newUser'),
            },
        ],
    },
    {
        path: '/patient-profile',
        component: DashboardLayout,
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '',
                name: 'PatientProfile',
                component: () => import('@/views/patientProfile'),
            },
            {
                path: 'generate/prediction/:id',
                name: 'PatientProfilePrediction',
                meta: {
                    breadcrumbs: 'Patient Profile Generate Prediction',
                },
                component: () => import('@/views/patientProfile/generatePrediction'),
            },
            {
                path: ':operation/:id?',
                name: 'PatientProfileForm',
                meta: {
                    breadcrumbs: 'Patient Profile Form',
                },
                component: () => import('@/views/patientProfile/form'),
            },
        ],
    },
    {
        path: '/model-profile',
        component: DashboardLayout,
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '/',
                name: 'ModelProfile',
                meta: {
                    breadcrumbs: 'Model Profile List',
                },
                component: () => import('@/views/modelProfile'),
            },
            {
                path: ':operation/:id?',
                name: 'ModelProfileForm',
                meta: {
                    breadcrumbs: 'Model Profile Form',
                },
                component: () => import('@/views/modelProfile/form'),
            },],
    },
    {
        path: '/iadl-profile',
        component: DashboardLayout,
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '/',
                name: 'IadlProfile',
                meta: {
                    breadcrumbs: 'Iadl Profile List',
                },
                component: () => import('@/views/iadlProfile'),
            },
            {
                path: ':operation/:id?',
                name: 'IadlProfileForm',
                meta: {
                    breadcrumbs: 'Iadl Profile Form',
                },
                component: () => import('@/views/iadlProfile/form'),
            },],
    },
    {
        path: '/frailty',
        component: DashboardLayout,
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '/',
                name: 'frailty',
                meta: {
                    breadcrumbs: 'Frailty List',
                },
                component: () => import('@/views/frailty'),
            },
            {
                path: ':operation/:id?',
                name: 'frailtyForm',
                meta: {
                    breadcrumbs: 'Frailty Form',
                },
                component: () => import('@/views/frailty/form'),
            },
        ],
    },
    {
        path: '/quality/of/life',
        component: DashboardLayout,
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '/',
                name: 'qualityOfLife',
                meta: {
                    breadcrumbs: 'Quality Of Life List',
                },
                component: () => import('@/views/qualityOfLife'),
            },
            {
                path: ':operation/:id?',
                name: 'qualityOfLifeForm',
                meta: {
                    breadcrumbs: 'Quality Of Life Form',
                },
                component: () => import('@/views/qualityOfLife/form'),
            },
        ],
    },
    {
        path: '*',
        beforeEnter: (to, from, next) => {
            next('/404');
        },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/error'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});


router.beforeEach((to, from, next) => {
    if (
      hasAuthUser()
      && getAuthUser().role !== 'admin' &&
      to.matched.some(record => record.meta.authAdminRequired)
    ) {
        next({
            name: 'AdminLogin',
        });
    }
    if (
      hasAuthUser()
      && getAuthUser().role == 'admin' &&
      to.matched.some(record => record.meta.authRequired)
    ) {
        next({
            name: '404',
        });
    }
    if (
      ( to.matched.some(record => record.meta.authRequired)
        || to.matched.some(record => record.meta.authAdminRequired) )
      && !hasAuthUser()
    ) {
        removeStorage('auth');
        next({
            name: 'Login',
        });
    }


    next();
});

export default router;

<template>
    <div class="dashboard-page container-fluid overflow-hidden">
        <div class="row vh-100 overflow-auto">
            <Asidebar />
            <router-view />
        </div>
    </div>
</template>

<script>
import Asidebar from '@/components/Asidebar.vue';

export default {
    components: {
        Asidebar,
    },
    data() {
        return {};
    },
};
</script>

<template>
    <div id="app">
        <localization>
            <vue-snotify></vue-snotify>
            <router-view />
        </localization>
    </div>
</template>

<script>
import Localization from './Localization';


export default {
    name: 'App',
    components: {
        Localization,
    },
};
</script>

<template>
    <div>
        <Header />
        <div class="dashboard-pages my-5 pt-5">
            <router-view />
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    }
};
</script>

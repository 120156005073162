import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { removeStorage, setStorage } from '@/Util/auth';

Vue.use(Vuex);

const state = {
    user: null,
    loader: false,
    locale: 'en-US',
};

const store = new Vuex.Store({
    state,
    getters: {
        user: (state) => {
            return state.user;
        },
        loader: (state) => {
            return state.loader;
        },
        locale: (state) => {
            return state.locale;
        }
    },
    actions: {
        user: (context, user) => {
            context.commit('user', user);
        },
        loader: (context, data) => {
            context.commit('LOADER', data);
        },
        change_setting: (context, data) => {
            context.commit('CHANGE_SETTING', data);
        }
    },
    mutations: {
        user: (state, user) => {
            state.user = user;
            if ( state.user === null ) {
                removeStorage('auth');
            }
        },
        LOADER: (state, data) => {
            state.loader = data;
        },
        CHANGE_SETTING(state, payload) {
            setStorage("locale", payload);
            state.locale = payload;
        },
    },
    plugins: [createPersistedState()]
});

export default store;

<template>
    <div class="col-12 col-sm-4 col-md-3 col-xl-2 d-flex sticky-top p-0" id="left-asidebar">
        <div
            class="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-3 px-sm-0"
        >
            <a href="/" class="text-decoration-none me-auto mx-sm-auto d-flex logo-wrapper">
                <img src="@/assets/img/icare4old_logo.svg" alt="logo" class="logo" />
            </a>
            <admin-sidebar v-if="user.role == 'admin'" />
            <user-sidebar v-if="user.role == 'user'" />
            <pre-pilot-user-sidebar v-if="user.role == 'pre-pilot'" />
        </div>
    </div>
</template>

<style></style>

<script>
import { mapGetters } from "vuex";
import AdminSidebar from "./roles/admin/sidebar";
import UserSidebar from "./roles/users/sidebar";
import PrePilotUserSidebar from "./roles/prePilotUser/sidebar";

export default {
    components: { AdminSidebar, UserSidebar, PrePilotUserSidebar },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['user'])
    }
};
</script>

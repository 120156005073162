import Vue from 'vue';
import App from './App.vue';

import router from "./router";
import {
    calculatePercentageNumberFormat,
    checkingSystemCode,
    dateFormat,
    handleSyncRequestLoader,
    roundNumberFormat
} from '@/Util/auth';
import store from './store';
import _ from 'lodash';
import BootstrapVue from 'bootstrap-vue';
import CrudTable from "@/components/CrudTable";
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import { i18n } from './Localization';
import CustomToolTip from "@/components/CustomToolTip";
import CustomFileUploader from "./components/FileUploader";
import VueToast from 'vue-toast-notification';
import NotifyMixin from "@/Util/NotifyMixin";

import 'vue2-datepicker/index.css';
import 'vue-select/dist/vue-select.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/main.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vue-datetime/dist/vue-datetime.css';
import './assets/scss/custom.scss';
import 'vue-toast-notification/dist/theme-bootstrap.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapIconsPlugin);
Vue.use(VueToast, {
    position: 'top-right'
});

Vue.component('crud-table', CrudTable);
Vue.component('v-select', vSelect);
Vue.component('vue-datetime', DatePicker);
Vue.component('custom-tooltip', CustomToolTip);
Vue.component('custom-file-uploader', CustomFileUploader);


Vue.prototype._ = _;
Vue.config.productionTip = false;
Vue.prototype.$global = {
    dateFormat: (date, format = 'DD/MM/YYYY') => ( ( date ) ? dateFormat(date, format) : null ),
    calculatePercentageNumberFormat: (number) => calculatePercentageNumberFormat(number),
    roundNumberFormat: (number, digit) => roundNumberFormat(number, digit),
    checkingSystemCode: () => checkingSystemCode()
};
Vue.mixin(NotifyMixin);

new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
    created() {
        handleSyncRequestLoader(store, process.env.VUE_APP_API_URL);
    },
}).$mount('#app');

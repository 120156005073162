<!-- CustomToolTip.vue -->
<template>
  <span class="tooltip-component">
    <b-tooltip :target="`tooltip-icon-${id}`" :placement="placement" variant="info" triggers="hover focus click">{{
            content
        }}</b-tooltip>
    <i :id="`tooltip-icon-${id}`" class="fas fa-info-circle text-info" v-if="!icon"></i>
    <i :id="`tooltip-icon-${id}`" :class="icon" v-else></i>
  </span>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
            required: true
        },
        placement: {
            type: String,
            required: false,
            default() {
                return 'top';
            }
        },
        id: {
            type: [String, Number],
            required: true
        },
        icon: {
            required: false
        }
    }
};
</script>

<style scoped>
.tooltip-component {
    display: inline-flex !important;
    align-items: center !important;
    margin-left: 5px !important;
}

.tooltip-component .fas {
    pointer-events: auto !important; /* Ensure the icon is interactive */
    cursor: pointer !important; /* Change cursor to pointer on hover */
}
</style>

<template>
    <div class="login-page">
        <router-view />
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>

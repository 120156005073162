<template>
    <div>
        <router-view :locale="locale"></router-view>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueI18n from 'vue-i18n';
    import english from './locales/en-US';
    import italian from './locales/it-IT';
    import czech from './locales/cs-CZ';
    import finnish from './locales/fi-FI';
    import polish from './locales/pl-PL';
    import dutch from './locales/nl-NL';
    import flemish from './locales/be-NL';

    import { mapState } from "vuex";

    const locales = {
        'en-US': english,
        'it-IT': italian,
        'cs-CZ': czech,
        'fi-FI': finnish,
        'pl-PL': polish,
        'be-NL': flemish,
        'nl-NL': dutch
    };

    Vue.use(VueI18n);
    export const i18n = new VueI18n({
        locale: 'en-US',
        fallbackLocale: 'en-US',
        silentTranslationWarn: true,
        messages: {
            'en-US': locales['en-US'].messages,
            'it-IT': locales['it-IT'].messages,
            'cs-CZ': locales['cs-CZ'].messages,
            'fi-FI': locales['fi-FI'].messages,
            'pl-PL': locales['pl-PL'].messages,
            'be-NL': locales['be-NL'].messages,
            'nl-NL': locales['nl-NL'].messages
        },
    });

    export default {
        mounted() {
            this.$i18n.locale = this.locale;
        },
        computed: {
            ...mapState(['locale']),
        },
        watch: {
            'locale': function (val) {
                this.$i18n.locale = val;
            }
        }
    };
</script>

<template>
    <div>
        <h6 class="mb-2" v-if="user?.role !== 'pre-pilot'">{{ $t('title.attachments') }}:</h6>
        <div class="d-flex" v-if="user?.role == 'admin'">
            <div>
                <input type="file" ref="file" @change="onFileChange" placeholder="asdjasd" >
                <span class="text-danger" v-if="formErrors.has('file')">
                    {{ formErrors.first('file') }}
                </span>
            </div>
            <div>
                <treeselect v-model="translation" class="form-input" :options="dropdowns.languages" />
                <span class="text-danger" v-if="formErrors.has('translation')">
                    {{ formErrors.first('translation') }}
                </span>
            </div>
            <div>
                <button type="submit" @click="uploadFile" v-if="!isPrePilotUser">{{ $t('title.upload') }}</button>
            </div>
        </div>
        <i class="fa fa-spinner fa-spin" v-if="loader"></i>

        <div class="table-responsive my-2" v-if="!isPrePilotUser">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>{{ $t('title.name') }}</th>
                        <th v-if="user?.role == 'admin'">{{ $t('title.translation') }}</th>
                        <th>{{ $t('title.action') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="file in files" :key="file.id">
                        <td>{{ file.name }}</td>
                        <td v-if="user?.role == 'admin'">{{ file.translation }}</td>
                        <td>
                            <a :href="file.download_url" target="_blank">
                                <i class="fa fa-download me-2"></i>
                            </a>
                            <i class="fa fa-trash" @click="deleteFile(file.id)"></i>
                            <div>
                                <a :href="file.download_url" target="_blank" style="text-decoration: none">
                                    <img :src="file.download_url" width="300" class="img-responsive" />
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot v-if="!files.length">
                    <tr>
                        <td colspan="3" align="center">
                            {{ $t('title.noRecordFound') }}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12 my-1" v-for="(file,fIndex) in files" :key="fIndex">
                    <a :href="file.download_url" target="_blank" style="text-decoration: none">
                        <img :src="file.download_url" class="img-responsive w-100" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { request } from "@/Util/Request";
import { mapGetters } from "vuex";
import Error from "@/Util/Error";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    components: { Treeselect },
    props: {
        id: {
            default() {
                return null;
            },
            required: false
        },
        documents: {
            default() {
                return [];
            },
            required: false
        }
    },
    data() {
        return {
            files: this.documents,
            selectedFile: null,
            formErrors: new Error({}),
            dropdowns: {
                languages: []
            },
            translation: null
        };
    },
    mounted() {
        this.getDropdowns();
    },
    methods: {
        onFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        async uploadFile() {
            this.formErrors = new Error({});
            const formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('object_id', this.id);
            formData.append('object_type', 'patient');
            formData.append('translation', this.translation);

            try {
                const response = await request({
                    method: 'post',
                    url: `/documents`,
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    }
                });

                const { data } = response;

                this.files.push(data);
                this.selectedFile = null;
                this.$refs.file.value = '';
                this.translation = null;
                this.notifySuccessWithMsg(this.$t('title.fileUploadSuccessMsg'));

            } catch (error) {
                this.notifyError();
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        async deleteFile(fileId) {
            if ( confirm("Are you sure want to delete?") ) {
                try {
                    const response = await request({
                        method: 'delete',
                        url: `/documents/${ fileId }`
                    });
                    this.files = this.files.filter(file => file.id !== fileId);
                    this.notifySuccessWithMsg(this.$t('title.fileDeleteMsg'));
                } catch (error) {
                    this.notifyError();
                }
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/admin/dropdowns/languages`
                });

                const { data } = response;
                this.dropdowns.languages = data;

            } catch (error) {
            }
        },
    },
    computed: {
        ...mapGetters(['loader', 'user']),
        isPrePilotUser() {
            return ( this.user ? this.user?.role == 'pre-pilot' : false );
        },
    }
};
</script>
